<template>
	<v-navigation-drawer
		v-model="drawer"
		app dark
		width="300"
		color="primary"
		:permanent="mdAndUp && drawer"
		:temporary="!mdAndUp && drawer"
	>
		<v-card dark color="transparent" flat tile>
			<v-card-title class="d-flex align-center">
				<v-avatar color="grey lighten-4" size="30">
					<span class="primary--text">H</span>
				</v-avatar>
				<div class="px-3">HAAJIR ADMINISTRATION</div>
			</v-card-title>
			<div class="py-6"></div>
			<div class="drawer-list">
				<div
					class="drawer-item"
					v-for="(item, index) in drawerItems"
					:key="index"
					:class="{
						'drawer-item-active': item.to === $route.path
					}"
					@click="item.to !== $route.path ? $router.push(item.to) : ''"
					:datasrc="item.to"
				>
					<div class="drawer-item-content">
						<div class="drawer-item-icon">
							<v-icon>{{ item.icon }}</v-icon>
						</div>
						<div class="drawer-item-title">{{ item.title }}</div>
					</div>
				</div>
			</div>
		</v-card>
		<div class="close-container" @click.stop="SET_DRAWER_STATE(false)">
			Close
			<div class="bottom-line"></div>
		</div>
	</v-navigation-drawer>
</template>

<script>
import {mapMutations} from "vuex";

export default {
	name: "HomeDrawer",
	computed: {
		drawer: {
			get() {
				return this.$store.getters.drawerState;
			},
			set(val) {
				this.SET_DRAWER_STATE(val);
			}
		},
		mdAndUp() {
			return this.$vuetify.breakpoint.mdAndUp
		},
		drawerItems() {
			return [
				{title: "Home", icon: "mdi-home-variant-outline", to: "/"},
				{title: "Staffs", icon: "mdi-account-group-outline", to: "/staffs"},
				{title: "Attendance", icon: "mdi-fingerprint", to: "/attendance"},
				{title: "Leave Requests", icon: "mdi-frequently-asked-questions", to: "/leave-requests"},
				{title: "Report", icon: "mdi-chart-box", to: "/report"},
			];
		}
	},
	created() {
		this.SET_DRAWER_STATE(
			this.$vuetify.breakpoint.width > this.$vuetify.breakpoint.sm
		);
	},
	mounted() {
		let list = document.querySelector(".drawer-list");
		let listItems = document.querySelectorAll(".drawer-item");

		function activeLink() {
			listItems.forEach(item => {
				item.classList.remove("drawer-item-active");
				this.classList.add("drawer-item-active");
			});
		}

		function arrangeActiveLink() {
			const currentUrl = window.location.href;
			const urlParts = currentUrl.split("#");
			listItems = this.querySelectorAll(".drawer-item");
			listItems.forEach(item => {
				item.classList.remove("drawer-item-active");
				if (urlParts[1] === item.getAttribute("datasrc"))
					item.classList.add("drawer-item-active");
			});
		}

		listItems.forEach(item => item.addEventListener("mouseover", activeLink));
		list.addEventListener("mouseout", arrangeActiveLink);
	},
	methods: {
		...mapMutations(["SET_DRAWER_STATE"])
	}
};
</script>

<style scoped lang="scss">
@import "src/styles/variables.scss";

.drawer-list {
	padding-left: 12px;

	.drawer-item {
		cursor: pointer;
		padding: 14px 0;

		.drawer-item-content {
			display: flex;
			align-items: center;

			.drawer-item-icon {
				padding-right: 30px;
				padding-left: 20px;
			}

			.drawer-item-title {
				font-weight: 600;
			}
		}
	}

	.drawer-item-active {
		background-color: whitesmoke;
		border-top-left-radius: 26px;
		border-bottom-left-radius: 26px;
		color: $primary;

		.drawer-item-content::before {
			position: absolute;
			right: 0;
			content: "";
			width: 50px;
			height: 50px;
			border-radius: 50%;
			background-color: $primary;
			margin-top: -105px;
			box-shadow: 35px 35px 0 10px whitesmoke;
			pointer-events: none;
		}

		.drawer-item-content::after {
			position: absolute;
			right: 0;
			content: "";
			width: 50px;
			height: 50px;
			border-radius: 50%;
			background-color: $primary;
			margin-top: 104px;
			box-shadow: 35px -35px 0 10px whitesmoke;
			pointer-events: none;
		}

		.drawer-item-icon {
			::v-deep.v-icon {
				color: $primary;
			}
		}
	}
}

.close-container {
	position: absolute;
	bottom: 12px;
	right: 12px;
	color: whitesmoke;

	.bottom-line {
		transition: width 0.5s;
		transition-duration: 0.5s;
		position: absolute;
		background-color: whitesmoke;
		height: 2px;
		width: 0;
		right: 0;
	}
}

.close-container:hover {
	cursor: pointer;

	.bottom-line {
		width: 100%;
	}
}

.drawer-item-disabled {
	cursor: auto;
}
</style>
